import React, { Component } from 'react';
import { path } from 'ramda';

import Conditions from './Conditions';
import VacancyList from "./VacancyList";
import BoxRed from "./BoxRed";

export default class Main extends Component {
    render() {
        return <div>
            <Conditions />
            <VacancyList {...this.props} vacancySubmit={path(['match', 'params', 'vacancy'], this.props)} />
            <BoxRed />
        </div>;
    }
}
