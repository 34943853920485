import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { path } from 'ramda';
import axios from 'axios';

import Vacancy from './Vacancy';
import { COMPANY, DOMAIN } from '../constants/domain';
import { getUtmFromSearch, getUtmTool } from '../utils/utm';

const VacancyList = ({ vacancySubmit, history }) => {
    const [modal, setModal] = useState(vacancySubmit || null);

    const mutation = useMutation(({ vacancy, domain, values }) => {
        const url = vacancy ? `vacancy/${vacancy}/${domain}` : `domain/${domain}`;

        return axios.post(`/api/public/${url}/applicant`, {
            ...values,
            _utm: getUtmFromSearch(),
            _tool: getUtmTool(),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'ru',
            }
        })
    });

    const { isLoading, error, data } = useQuery("vacancies", () =>
        axios.get(`/api/public/vacancy`, {
            params: {
                filter: {
                    companyCode: COMPANY,
                    domainName: DOMAIN,
                },
                relations: ['cities', 'classifiers', 'domains', 'domains.company', 'domains.vacancyFormPreset']
            }
        }).then((res) => res.data)
    );

    const vacancies = (path(['items'], data) || []);
    const domainForm = path(['items', 0, '_relations', 'domains', 0], data) || {};

    useEffect(() => {
        if (vacancySubmit && !((vacancySubmit === 'submit') || (vacancySubmit === 'submit-success'))) {
            setModal(null);
            history.replace('/');
        }
    }, [vacancySubmit, history]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setModal(`${modal}-success`);
            history.push(`/${modal}-success`);
            mutation.reset();
        }
    }, [mutation.isSuccess, modal, mutation, history]);

    const openModal = modal => {
        history.push(`/${modal}`);
        setModal(modal);
    };
    const closeModal = () => {
        history.push(`/`);
        setModal(null);
    };

    return (
        <div className="vacancy-list">
            <div className="container">
                {/* eslint-disable-next-line */}
                <a name="vacancies" id="vacancies" />
            </div>
            <div className="row">
                <div className="col-1">
                    <div className="content">
                        <div>
                            <h3>Начальник Отдела реализации перспективных проектов в&nbsp;Департаменте повышения эффективности розничного бизнеса ПАО &laquo;ЛУКОЙЛ&raquo;</h3>
                            <div className="item">
                                <div className="title">ОБЯЗАННОСТИ</div>
                                <ul>
                                    <li>анализ прогнозов развития рынков присутствия, перспективных рынков, а&nbsp;также лучших мировых бизнес-практик в&nbsp;области нефтепродуктообеспечения, цифровых сервисов, розничной торговли и&nbsp;ресторанного бизнеса</li>
                                    <li>формирование качественных и&nbsp;количественных стратегических ориентиров развития розничного бизнеса в&nbsp;РФ и&nbsp;за&nbsp;рубежом, включая поиск и&nbsp;продвижение новых перспективных направлений и&nbsp;проектов, в&nbsp;т.ч. относительно запуска розничного бизнеса в&nbsp;новых регионах</li>
                                    <li>обеспечение качественной реализации утвержденных проектов</li>
                                    <li>определение приоритетных направлений инвестирования в&nbsp;целях долгосрочного повышения доходности используемого капитала и&nbsp;повышения капитализации</li>
                                    <li>внедрение передовых бизнес-практик и&nbsp;технико-информационных решений</li>
                                    <li>обеспечение качественной реализации утвержденных проектов</li>
                                    <li>сопровождение инновационной деятельности сбытового бизнеса</li>
                                    <li>экспертная и&nbsp;операционная поддержка существенных вопросов деятельности розничного бизнеса, кроссфункциональных и&nbsp;приоритетных инвестиционных проектов</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="title">УСЛОВИЯ</div>
                                <ul>
                                    <li>трудоустройство по ТК РФ</li>
                                    <li>стабильная заработная плата</li>
                                    <li>корпоративное обучение</li>
                                    <li>полный социальный пакет</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="title">ТРЕБОВАНИЯ</div>
                                <ul>
                                    <li>высшее профессиональное образование (техническое, финансово-экономическое)</li>
                                    <li>опыт работы не&nbsp;менее 5&nbsp;лет, в&nbsp;т.ч.&nbsp;на&nbsp;руководящих должностях в&nbsp;сфере нефтепродуктообеспечения</li>
                                    <li>знание английского языка не&nbsp;ниже upper-intermediate (B2)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="controls">
                        <button className="btn btn-outline btn-black" onClick={() => openModal('submit')}>ОТКЛИКНУТЬСЯ</button>
                    </div>
                </div>
            </div>
            <Modal isOpen={!!modal} onRequestClose={closeModal} ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Вакансия</h3>
                    <button className="btn-close" onClick={closeModal}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 8L8 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 8L24 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    { vacancySubmit === 'submit' ? (
                        error || (!isLoading && !vacancies.length) ? (
                            <p style={{ 'textAlign': 'center' }}>
                                {error ? (
                                    'Не удалось загрузить вакансию, попробуйте обновить страницу'
                                ) : (
                                    'Нет доступных вакансий'
                                )}
                            </p>
                        ) : (
                            isLoading ? 'Загрузка вакансии...' : (
                                <Vacancy
                                    mutation={mutation}
                                    vacancy={vacancies[0]}
                                    domain={domainForm}
                                />
                            )
                        )
                    ) : (
                        <h2>ВАША АНКЕТА НАПРАВЛЕНА В ОТДЕЛ ПО РАБОТЕ С ПЕРСОНАЛОМ</h2>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default VacancyList;
