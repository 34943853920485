import React from 'react';

const BoxRed = () => {
    return (
        <div className="box-red">
            <div className="container">
                {/* eslint-disable-next-line */}
                <a name="form" id="form" />
                <h2>Будь всегда в движении, развивайся вместе с нами. <br />Развивайся в ЛУКОЙЛ</h2>
                {/*<button className="btn btn-outline">ОСТАВИТЬ ЗАЯВКУ</button>*/}
            </div>
        </div>
    );
}

export default BoxRed;
