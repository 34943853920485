import React from 'react';

import footer from '../assets/img/footer.svg';

const Footer = () => {
  return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div><img src={footer} alt="logo"/></div>
            <div>
              <span>© ALL RIGHTS RESERVED</span>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Footer;
