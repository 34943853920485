import React from 'react';

const Conditions = () => {
    return (
        <div className="conditions">
            {/* eslint-disable-next-line */}
            <a name="terms" id="terms" />
            <div className="row">
                <div className="col-1-4">
                    <div className="content">
                        <h2>&laquo;ЛУКОЙЛ&raquo; приглашает к&nbsp;сотрудничеству лучших соискателей</h2>
                        <p>ОСТАВЬ ЗАЯВКУ НА&nbsp;УЧАСТИЕ В&nbsp;КОНКУРСЕ И&nbsp;ПОЛУЧИ ВСЕ ПРЕИМУЩЕСТВА РАБОТЫ В&nbsp;КОМАНДЕ ПРОФЕССИОНАЛОВ</p>
                    </div>
                </div>
                <div className="col-3-4">
                    <div className="item">
                        <h3>возможность принимать непосредственное участие в&nbsp;интересных и&nbsp;масштабных проектах</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>возможность для профессионального и&nbsp;карьерного роста</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>конкурентоспособный компенсационный пакет</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>КОНКУРЕНТНАЯ ЗАРАБОТНАЯ ПЛАТА</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>развитие и&nbsp;необходимые условия для самореализации</h3>
                    </div>
                    <div className="item" />
                </div>
            </div>
        </div>
    );
}

export default Conditions;
