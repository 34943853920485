import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button';
import { QueryClient, QueryClientProvider } from "react-query";

import 'react-toastify/dist/ReactToastify.css';

import Main from './Main';
import Header from './Header';
import Footer from './Footer';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
        }
    }
});

const NoMatch = () => (
    <Redirect to='/' />
);

export default class App extends Component {
    render() {
        return <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Header />
                <div className="wrap-content">
                    <ToastContainer />
                    <Switch>
                        <Route path='/:vacancy' component={Main} />
                        <Route path='/' component={Main} exact />
                        <Route component={NoMatch} />
                    </Switch>
                </div>
                <ScrollUpButton ContainerClassName='scroll-container' />
                <Footer />
            </BrowserRouter>
        </QueryClientProvider>;
    }
}
