import React, { Component } from 'react';
import { path, pathOr } from 'ramda';
import Form from '@experium/findy-form';

import '@experium/findy-form/lib/styles/index.css';

const VacancyForm = ({ domain, vacancy, mutation, onSubmit }) => {
    return <Form
        fields={path(['_relations', 'vacancyFormPreset', 'questions'], domain)}
        onSubmit={onSubmit}
        opd={pathOr('', ['_relations', 'company', 'pdaSettings', 'text'], domain)}
        htmlOpdStyles={'.opd-html-form div > div { text-align: justify; }'}
        opdSettings={pathOr({}, ['_relations', 'company', 'pdaSettings'], domain)}
        allowFileExtensions={path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], domain)}
        captcha={path(['_relations', 'company', 'companySettings', 'captcha', 'domains'], domain)}
        captchaOptions={path(['_relations', 'domainFormPreset', 'options'], domain)}
        company={pathOr({}, ['_relations', 'company', 'id'], domain)}
        postFileUrl={`/api/public/file/${domain.id}`}
        getFileUrl={id => `${window.location.origin}/api/file/${id}`}
        language='ru'
        languageOrigin='ru'
        pdaLanguage='ru'
        serverErrors={path(['response', 'data', 'errors'], mutation.error)}
        submitting={mutation.isLoading}
        scrollContainerClassName={'.modal-body'}
    />;
}

export default class Vacancy extends Component {
    onSubmit = (values, formProps) => {
        const domain = this.props.domain.id;

        const id = path(['id'], this.props.vacancy);

        if (id) {
            return this.props.mutation.mutate({
                vacancy: id,
                domain,
                values,
            });
        }

        this.props.mutation.mutate({ vacancy: null, domain, values });
    }

    render() {
        return (
            <div className="wrap-form">
                <VacancyForm {...this.props} onSubmit={this.onSubmit} />
            </div>
        );
    }
}
